import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZpxTableComponent } from '../zpx-table/zpx-table.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { PassholderTableDataSource } from './passholder-table.datasource';
import {
  PassholderForTable,
  PASSHOLDER_TYPES,
  ZpxFrontendPageParams,
  ZpxApiPassholderParams
} from '@src/app/models/zpx-api.model';

import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { filter, first, takeUntil, withLatestFrom } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PassholderModalComponent } from '../passholder-modal/passholder-modal.component';
import { AppService } from '@src/app/app.service';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { MatIconModule } from '@angular/material/icon';
import { ManageGroupModalComponent } from '../manage-group-modal/manage-group-modal.component';
import { ImportModalComponent } from '../import-modal/import-modal.component';
import { SelectedCompanyService } from '@src/app/services/selected-company/selected-company.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-passholder-table',
  standalone: true,
  providers: [PassholderTableDataSource],
  imports: [
    CommonModule,
    ZpxTableComponent,
    PassholderModalComponent,
    MatIconModule
  ],
  templateUrl: './passholder-table.component.html',
  styleUrls: ['./passholder-table.component.scss']
})
export class PassholderTableComponent implements OnInit, OnDestroy {
  constructor(
    private passholderDataSource: PassholderTableDataSource,
    private getEnvService: GetEnvironmentService,
    private dialog: MatDialog,
    private appService: AppService,
    private zpxApiService: ZpxApiService,
    public selectedCompanyService: SelectedCompanyService,
    private elementRef: ElementRef
  ) {}

  @Input() passholderType: PASSHOLDER_TYPES = PASSHOLDER_TYPES.STUDENT;

  private onDestroy$ = new Subject<void>();
  columns$ = new BehaviorSubject(null);

  dataSource = this.passholderDataSource;
  offset = 0;

  currentPage: number = 1;

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  totalResults: number = 0;

  selectedPassholderIds: string[] = [];

  setPagingParams(
    feParams: ZpxFrontendPageParams,
    beParams: ZpxApiPassholderParams,
    total: number
  ): ZpxApiPassholderParams {
    if (feParams.page === 1) {
      this.offset = 0;
    } else {
      if (feParams.page > this.currentPage) {
        const lastPage = Math.floor(total / feParams.per_page);

        // + 1 to lastPage to account for non-array page numbering, e.g. a lastPage of 10 would be 11 for frontend
        const isLastPage = lastPage + 1 === feParams.page;

        if (isLastPage) {
          this.offset = total - feParams.per_page;
        } else {
          // prevent setting an offset that is greater than data array length
          // will never actually occur in the UI, but put this safeguard in for edgecase testing purposes
          const nextOffset = this.offset + feParams.per_page;
          this.offset = nextOffset < total ? nextOffset : this.offset;
        }
      } else {
        // prevent setting offset to be a negative number
        // will never actually occur in the UI, but put this safeguard in for edgecase testing purposes
        const nextOffset = this.offset - feParams.per_page;
        this.offset = nextOffset >= 0 ? nextOffset : 0;
      }
    }

    this.currentPage = feParams.page;

    const newPagingParams = {
      ...beParams,
      offset: this.offset,
      limit: feParams.per_page
    };

    return newPagingParams;
  }

  ngOnInit(): void {
    this.zpxApiService
      .getPassholderTypes()
      .pipe(first())
      .subscribe((pTypes) => {
        const pTypeId = pTypes.find((t) => t.name === this.passholderType).id;
        this.appService.passholderTypeId$.next(pTypeId);
      });
    this.appService.passholderType$.next(this.passholderType);
    this.dataSource.paginationParams$
      .pipe(
        filter((fePageParams) => Boolean(fePageParams.page)),
        withLatestFrom(this.dataSource.total$),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([fePageParams, total]) => {
        const newParams = this.setPagingParams(
          fePageParams,
          {
            passholder_type_string: this.passholderType
          },
          total
        );
        this.dataSource.getPassholdersForTable(newParams);
      });

    const standardColumns = this.dataSource.getStandardColumns();

    this.dataSource.customColumnsForTable$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((customColumns) => {
        this.columns$.next([
          ...standardColumns,
          ...customColumns,
          this.dataSource.getEditColumn()
        ]);
      });
  }

  onClick(passholder: PassholderForTable) {
    if (passholder.class.includes('material-icons')) {
      this.openEditPassholder(passholder);
    }
  }

  onSelect(e: SelectionModel<string>) {
    this.selectedPassholderIds = e.selected;
  }

  deactivateSelected() {
    if (this.selectedPassholderIds.length) {
      this.zpxApiService
        .deactivatePassholders(this.selectedPassholderIds)
        .pipe(first())
        .subscribe(() => {
          const newData = this.selectedPassholderIds.reduce(
            (acc, currentId) => {
              acc[currentId] = true;
              return acc;
            },
            {}
          );
          this.dataSource.deactivatedPassholders$.next(newData);

          // a hack to unset the header checkmark to be blank again after we reset the table by removing deactivated users
          const headerCheckbox = this.elementRef.nativeElement.querySelector(
            'mat-header-cell mat-checkbox'
          );
          headerCheckbox.classList.remove('mat-checkbox-indeterminate');
          headerCheckbox.classList.remove('mat-checkbox-checked');
        });
    }
  }

  openAddPassholder() {
    this.dialog.open(PassholderModalComponent, {
      data: {
        passholder: null,
        action: 'add'
      }
    });
  }

  openEditPassholder(passholder: PassholderForTable) {
    this.dialog.open(PassholderModalComponent, {
      data: {
        passholder: {
          ...passholder
        },
        type: this.passholderType,
        action: 'edit'
      }
    });
  }

  openManageGroupsDialog() {
    this.dialog.open(ManageGroupModalComponent);
  }

  openImportDialog() {
    this.dialog.open(ImportModalComponent, {
      height: '85vh',
      width: '55vw',
      minWidth: '40vw'
    });
  }

  ngOnDestroy(): void {
    this.dataSource.onTableDestroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.columns$.complete();
  }
}
