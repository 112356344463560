import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SearchableDropdownModel,
  ZuiFiltersBarComponent
} from '@zonar-ui/filter';
import { FormGroup } from '@angular/forms';
import {
  PASSHOLDER_TYPES,
  ZpxApiFilterNames
} from '@src/app/models/zpx-api.model';
import { TablePassholdersFilterBarService } from './table-passholders-filter-bar.service';
import { AppService } from '@src/app/app.service';
import { combineLatest, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { GetDivisionsService } from '@src/app/services/get-divisions/get-divisions.service';
import { TablePassholdersFilterGroupValue } from './models/table-passholders-filter-bar.model';
import _ from 'lodash';

@Component({
  selector: 'app-table-passholders-filter-bar',
  standalone: true,

  imports: [CommonModule, ZuiFiltersBarComponent],
  templateUrl: './table-passholders-filter-bar.component.html',
  styleUrls: ['./table-passholders-filter-bar.component.scss']
})
export class TablePassholdersFilterBarComponent implements OnInit, OnDestroy {
  filterList: SearchableDropdownModel[];
  filterFormGroup = new FormGroup({});
  selectedDivisionId: string = null;
  constructor(
    public filterBarService: TablePassholdersFilterBarService,
    private appService: AppService,
    private divisionsService: GetDivisionsService
  ) {}

  private _passholderType: PASSHOLDER_TYPES;
  private unsubscribe$ = new Subject<void>();
  shouldSendDivisionId: boolean = null;
  changedEvent: string = null;
  @Input()
  set passholderType(passholderType: PASSHOLDER_TYPES) {
    this._passholderType = passholderType;
  }
  get passholderType(): PASSHOLDER_TYPES {
    return this._passholderType;
  }

  // removes division filter if user is single div user
  removeDivisionFilter() {
    const first: SearchableDropdownModel = this.filterList[0];
    if (first.options.fgControlName === ZpxApiFilterNames.DIVISION_ID) {
      this.filterList.shift();
    }
  }

  setDivisonDefaultSelection(divisionId: string) {
    this.filterList[0] = this.filterBarService.getDivisonModel(divisionId);
  }

  setCustomColumns(singleDivUser = false) {
    const customColumnsAndDivisionId$ = combineLatest([
      this.filterBarService.getCustomColumnFilters$(this.passholderType),
      this.appService.selectedDivisionId$
    ]);

    return customColumnsAndDivisionId$.pipe(
      map(([customColumnFilters, divisionId]) => {
        if (customColumnFilters.length) {
          const currentLastItemName =
            this.filterList[this.filterList.length - 1].options.fgControlName;

          const incomingLastItemName =
            customColumnFilters[customColumnFilters.length - 1].options
              .fgControlName;

          // indicates that the displayed custom columns are the same as the incoming ones, so return early or else we get dupes
          if (currentLastItemName === incomingLastItemName) {
            return;
          }
          // otherwise, get rid of the previously set custom columns
          this.filterList = this.filterList.filter(
            (f) => !(f as any).customColumnId
          );

          // add the new custom columns
          this.filterList = this.filterList.concat(customColumnFilters);

          // if this is a multi-division user who explicitly selected a division on app load, reset their division selection to reflect the current division
          if (divisionId && !singleDivUser) {
            this.setDivisonDefaultSelection(divisionId);
          }

          this.filterBarService.setCustomColumnsFiltersOptions(
            this.passholderType,
            customColumnFilters
          );

          return;
        }

        // if no custom columns, get rid of the previously set custom columns in the case of switching from one division w/ CC to another div w/o CC
        this.filterList = this.filterList.filter(
          (f) => !(f as any).customColumnId
        );
        if (divisionId && !singleDivUser) {
          this.setDivisonDefaultSelection(divisionId);
        }
      })
    );
  }

  ngOnInit() {
    this.appService.selectedDivisionId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((id) => {
        this.selectedDivisionId = id;
      });
    this.filterList = this.filterBarService.getCommonColumnsFilters();
    this.filterBarService.setCommonColumnFiltersOptions(this.passholderType);

    this.divisionsService
      .isSingleDivisionUser()
      .pipe(
        map((singleDivUser) => {
          if (singleDivUser) {
            this.removeDivisionFilter();
          }
          return singleDivUser;
        }),
        switchMap((singleDivUser) => this.setCustomColumns(singleDivUser)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changed(e) {
    if (this.changedEvent === null) this.changedEvent = e;
    if (!_.isEqual(this.changedEvent, e)) {
      this.changedEvent = e;
      this.shouldSendDivisionId =
        Boolean(e?.division_id?.selected) && Object.keys(e).length === 1;

      if (this.shouldSendDivisionId) {
        const incomingDivisionId = e.division_id.selected;

        // saving of selectedDivisionId prevents emissions of the same division id and prevents redundant api calls
        if (this.selectedDivisionId !== incomingDivisionId) {
          this.selectedDivisionId = incomingDivisionId;
          this.appService.selectedDivisionId$.next(this.selectedDivisionId);
        }
      } else {
        // We're not switching division IDs, so the filter values need to be acted upon.
        // We need to do this little bit because divisionId is a string, and we also only
        // care about the state of the filters that have been selected
        const filterFormState: TablePassholdersFilterGroupValue =
          this.filterFormGroup.value;
        const selectedFilterObj = {};
        Object.keys(filterFormState)
          .filter(
            (ff) =>
              Array.isArray(filterFormState[ff]) &&
              filterFormState[ff].length > 0
          )
          .forEach(
            (selectedFilter) =>
              (selectedFilterObj[selectedFilter] =
                filterFormState[selectedFilter])
          );

        this.filterBarService.parseUserSelectedFilters(selectedFilterObj);
      }
    }
  }
}
